import { PageProps } from 'gatsby';
import { FC } from 'react';
import { Asterisk, ContentSection, D, Layout, SEO } from '~/components';
import { HeroCarousel, HeroItem } from '~/components/common/hero-carousel';
import { Frontmatter } from '~/config';
import FamilyPicture from '~/images/carousel/family-picture.jpg';
import FoldedArmsHair from '~/images/carousel/folded-arms-hair.jpg';
import PhotoMP4 from '~/video/Holding-up-photo.mp4';
import PhotoWeb from '~/video/Holding-up-photo.webm';

// eslint-disable-next-line
const frontmatter: Frontmatter = {
  title: 'Library: Hero',
  order: 10,
  screenShoot: true,
};

const Page: FC<PageProps> = (props) => {
  return (
    <Layout>
      <SEO />
      <ContentSection>
        <HeroCarousel>
          <HeroItem
            transitionHero={{
              mainText: 'You may have patients like Mary',
              disclaimerPosition: 'right',
              disclaimerText:
                'Real patient with HER2+ HR+ breast cancer who had a pCR. NERLYNX was not commercially available at the time of treatment. Photograph taken April 2013.',
            }}
            mainHero={{
              imageOrVideo: [
                { src: PhotoMP4, type: 'mp4' },
                { src: PhotoWeb, type: 'webm' },
              ],
              mainText: 'She’s worked hard to get this far',
              disclaimerPosition: 'right',
              disclaimerText:
                'Real patient with HER2+ HR+ breast cancer who had a pCR. NERLYNX was not commercially available at the time of treatment. Photograph taken April 2013.',
              subText: (
                <>
                  Acting with urgency can help give every patient with HER2+ eBC
                  the chance of living disease free<sup>1</sup>
                </>
              ),
              button: {
                text: 'Review the risk of recurrence',
                to: '/risk-of-recurrence',
              },
            }}
          />
          <HeroItem
            transitionHero={{
              imageOrVideo: FamilyPicture,
              disclaimerPosition: 'right',
              disclaimerText:
                'Real patient with HER2+ HR+ breast cancer who had a pCR. NERLYNX was not commercially available at the time of treatment. Photograph taken August 2014.',
              mainText: (
                <span tw="uppercase md:(font-size[1.35em] line-height[1em])">
                  PREVENT RECURRENCE<sup>1,2,</sup>
                  <Asterisk />
                </span>
              ),
              subText: (
                <span>
                  Help reduce the risk of recurrence in HER2+ eBC<sup>†</sup>
                </span>
              ),
            }}
            mainHero={{
              imageOrVideo: FoldedArmsHair,
              disclaimerPosition: 'right',
              disclaimerText:
                'Real patient with HER2+ HR+ breast cancer who had a pCR. NERLYNX was not commercially available at the time of treatment. Photograph taken August 2014.',
              mainText: (
                <>
                  Take every opportunity to offer extended adjuvant treatment
                  after trastuzumab-based therapy
                  <sup>1,2,</sup>
                  <Asterisk />
                </>
              ),
              subText: (
                <>
                  Help reduce the risk of recurrence in HER2+ eBC<sup>†</sup>
                </>
              ),
              button: {
                text: 'Explore the data',
                to: '/early-stage-breast-cancer',
              },
            }}
          />
          <HeroItem
            mainHero={{
              imageOrVideo: FoldedArmsHair,
              mediaEffect: 'zoom',
              disclaimerPosition: 'right',
              disclaimerText:
                'Real patient with HER2+ HR+ breast cancer who had a pCR. NERLYNX was not commercially available at the time of treatment. Photograph taken August 2014.',
              mainText: 'Help her stay on treatment with dose escalation',
              subText: (
                <>
                  Start NERFLYNX at a lower dose and titrate up to the full
                  recommended dose to help manage <D />
                  <sup>3,‡</sup>
                </>
              ),
              button: {
                text: 'Manage {d} proactively',
                to: '/early-stage-breast-cancer',
              },
            }}
          />
        </HeroCarousel>
      </ContentSection>
    </Layout>
  );
};

export default Page;
